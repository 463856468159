import PropTypes from 'prop-types';
import React from 'react';
import Card from '@shoreag/base/Card';
import KeyValuePairs from '../KeyValuePairs';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';

const PolicyOverviewTab = ({ policies }) => {
  const filterKeyValuePairEntries = ([key, value]) =>
    !['customerNumberProductCode', 'id'].includes(key) &&
    !(Array.isArray(value) && !value.length);

  const datasetEntries = Object.entries(policies[0]).filter(
    filterKeyValuePairEntries
  );

  return (
    <Card sx={{ position: 'relative' }}>
      <h2>Metadata</h2>
      <KeyValuePairs
        autoTitleCase={false}
        pairs={datasetEntries.map(enhanceKeyValuePairEntries)}
      />
    </Card>
  );
};

PolicyOverviewTab.propTypes = {
  policies: PropTypes.shape({}).isRequired,
};

export default PolicyOverviewTab;
