import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import PolicyDashboardPage from '../../components/PolicyDashboardPage';
import PolicyCertificatesDashboardPage from '../../components/PolicyCertificatesDashboardPage';

const PoliciesRouter = (props) => (
  <Router>
    <PolicyCertificatesDashboardPage
      {...props}
      path="/policies/:policyNumber"
    />
    <PolicyDashboardPage {...props} path="/policies/" />
    <NotFoundPage default />
  </Router>
);

export default PoliciesRouter;
