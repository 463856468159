import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import React, { useState } from 'react';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import get from 'lodash/get';
import moment from 'moment';
import { Query } from 'react-apollo';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import allPolicy from '../../graphql/queries/all-policy.gql';
import setActionDropdown from '../../utilities/set-action-dropdown';
import LinkWithVersion from '../LinkWithVersion';
import Icon from '../Icon';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import DashboardFilterForm from '../DashboardFilterForm';
import dashboardConstants from '../../utilities/dashboard-constants';
import DashboardPaginator from '../DashboardPaginator';
import setDashboardURL from '../../utilities/set-dashboard-url';

const PolicyDashboardPage = ({ location, navigate }) => {
  const [showFilters, toggleFilter] = useState(true);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'master_policy',
  });

  return (
    <Route
      header={{
        icon: 'master-policy',
        rightContainer: (
          <ButtonGroups>
            <ToolbarButton
              icon="filter"
              label="Filters"
              onClick={() => {
                toggleFilter((prevCheck) => !prevCheck);
              }}
            />
          </ButtonGroups>
        ),
        title: 'Dashboard',
        type: 'master policy',
      }}
      isPrivate
    >
      <Box
        alignItems="center"
        display={{ md: 'flex' }}
        justifyContent="space-between"
      />
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allPolicy}
        variables={{
          searchBody: JSON.stringify({ ...filters, ...pageCriteria }),
        }}
      >
        {({ data, loading }) => {
          const allPolicy = get(data, 'allPolicy', []);
          return loading ? (
            <Card m={0} p={5}>
              <Spinner py="md" />
            </Card>
          ) : (
            <>
              <Card m={0} p={5}>
                <Table
                  header={[
                    'Policy Number',
                    'Customer Number',
                    'Product Code',
                    'Plan Code',
                    'Class Code',
                    'Coverage Tiers',
                    'Effective Date',
                    'Take Over',
                    '',
                  ]}
                  isLoading={loading}
                  onSortUpdate={(sortBy, orderBy) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: pageCriteria,
                        pathname: location.pathname,
                        sort: {
                          sortBy,
                          sortDirection: orderBy,
                        },
                      }),
                      { replace: true }
                    );
                  }}
                  rows={allPolicy.policies.map((policy) => {
                    const {
                      policyNumber,
                      customerNumber,
                      productCode,
                      planCd,
                      classificationGroupCodes,
                      coverageTiers,
                      effectiveDate,
                      takeOver,
                    } = policy;
                    const {
                      dropdownItems,
                      dropdownActions,
                    } = setActionDropdown([
                      {
                        action: `/policies/${policyNumber}`,
                        display: true,
                        label: ['view', 'View Certificates'],
                      },
                    ]);
                    return [
                      <LinkWithVersion
                        ellipsis={false}
                        link={`/policies/${policyNumber}`}
                        name={policyNumber}
                      />,
                      customerNumber,
                      productCode,
                      planCd || '-',
                      classificationGroupCodes?.length
                        ? classificationGroupCodes.join(', ')
                        : '-',
                      coverageTiers?.length ? coverageTiers.join(', ') : '-',
                      effectiveDate
                        ? moment(effectiveDate).format('MM/DD/YYYY')
                        : '-',
                      <Icon
                        svg={takeOver ? 'check' : 'fail'}
                        sx={{
                          color: takeOver ? 'success' : 'error',
                          ml: 4,
                          width: '1.25rem',
                        }}
                      />,
                      <Box sx={{ float: 'right' }}>
                        <DropdownMenu
                          button={<DotsIcon />}
                          itemActions={dropdownActions}
                          items={dropdownItems}
                        />
                      </Box>,
                    ];
                  })}
                  sortBy={sortCriteria.sortBy}
                  tableSx={{
                    fontSize: 2,
                  }}
                />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={allPolicy.policies.length}
              />
            </>
          );
        }}
      </Query>
    </Route>
  );
};

PolicyDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};
export default PolicyDashboardPage;
