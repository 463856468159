import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import Card from '@shoreag/base/Card';
import Input from '@shoreag/base/Input';
import Paginator from '@shoreag/base/Paginator';
import Box from '@shoreag/base/Box';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import { ParentSize } from '@vx/responsive';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { get, noop } from 'lodash';
import dashboardConstants from '../../utilities/dashboard-constants';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import LinkWithVersion from '../LinkWithVersion';
import allPolicyCertificate from '../../graphql/queries/all-policy-certificate.gql';
import Icon from '../Icon';
import TableWrapper from '../TableWrapper';

const filterConfig = {
  certificatePolicyNumber: {
    label: 'Certificate Policy Number',
    type: 'Input',
    valueType: 'S',
  },
  customerNumber: {
    label: 'Customer Number',
    type: 'Input',
    valueType: 'S',
  },
  effectiveDate: {
    label: 'Date',
    type: 'DatePicker',
    valueType: 'S',
  },
  filterOrder: [
    'certificatePolicyNumber',
    'customerNumber',
    'primaryParticipantName',
    'primaryParticipantTaxId',
    'policyStatusCd',
  ],
  policyStatusCd: {
    label: 'Policy Status Code',
    type: 'Input',
    valueType: 'S',
  },
  primaryParticipantName: {
    label: 'Primary Participant Name',
    type: 'Input',
    valueType: 'S',
  },
  primaryParticipantTaxId: {
    label: 'Primary Participant TaxId',
    type: 'Input',
    valueType: 'S',
  },
  sort: {
    sortBy: 'createdAt',
    sortDirection: 'desc',
  },
};

const PolicyCertificateTab = ({ policyNumber }) => {
  const [showFilters, toggleFilter] = useState(true);
  const { pageCriteria } = dashboardConstants.getCriterias({
    type: 'policy_certificate',
  });

  return (
    <ParentSize>
      {({ width }) => {
        const filterWidth = width / 5;
        const { filterOrder } = filterConfig;
        return (
          <Form
            initialValues={{
              offset: 0,
              pageSize: 10,
            }}
            onSubmit={noop}
            render={({ form, values }) => {
              const resetOffset = () => {
                form.change('offset', 0);
              };
              const filters = {
                ...filterConfig,
                ...{
                  masterPolicyNumber: {
                    type: 'Input',
                    values: policyNumber,
                    valueType: 'S',
                  },
                },
                operation: 'query',
                values: { ...values },
              };
              return (
                <>
                  <Card my={5} p={3}>
                    <Box
                      alignItems="center"
                      display={{ md: 'flex' }}
                      justifyContent="space-between"
                      p={3}
                    >
                      <Box alignItems="center" display={{ md: 'flex' }}>
                        <Box as="h2">Policy Certificates</Box>
                      </Box>
                      <ButtonGroups>
                        <ToolbarButton
                          active={showFilters}
                          icon="menu"
                          label="Filters"
                          onClick={() => {
                            toggleFilter((prevCheck) => !prevCheck);
                          }}
                        />
                      </ButtonGroups>
                    </Box>
                  </Card>
                  <Card my={5} p={0}>
                    <Box
                      sx={{
                        height: 'auto',
                        maxHeight: showFilters ? '300px' : 0,
                        overflow: showFilters ? 'visible' : 'hidden',
                        transition: 'max-height 0.5s ease-in-out',
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'flex-end',
                          bg: 'grays.2',
                          borderRadius: 2,
                          display: 'flex',
                          flexWrap: 'wrap',
                          fontSize: 2,
                        }}
                      >
                        {filterOrder.map((field) => {
                          const { label } = filterConfig[field];

                          return (
                            <Box
                              sx={{
                                p: 5,
                                width: filterWidth,
                              }}
                            >
                              <Field
                                component={Input}
                                label={label}
                                labelSx={{
                                  fontSize: 1,
                                }}
                                name={field}
                                onChange={resetOffset}
                                placeholder="Search..."
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Card>
                  <Card m={0} p={5}>
                    <Query
                      fetchPolicy="network-only"
                      notifyOnNetworkStatusChange
                      query={allPolicyCertificate}
                      variables={{
                        searchBody: JSON.stringify({
                          ...filters,
                          ...{
                            masterPolicyNumber: {
                              type: 'Input',
                              values: policyNumber,
                              valueType: 'S',
                            },
                          },
                          ...pageCriteria,
                        }),
                      }}
                    >
                      {({ data, loading }) => {
                        const allPolicyCertificate = get(
                          data,
                          'allPolicyCertificate',
                          []
                        );
                        return loading ? (
                          <Card m={0} p={5}>
                            <Spinner py="md" />
                          </Card>
                        ) : (
                          <>
                            <TableWrapper>
                              <Table
                                header={[
                                  'Certificate Number',
                                  'Customer',
                                  'Primary Participant',
                                  'Policy Status Code',
                                  'Effective Date',
                                ]}
                                isLoading={loading}
                                rows={allPolicyCertificate.certificates.map(
                                  (certificate) => {
                                    return [
                                      <LinkWithVersion
                                        ellipsis={false}
                                        link={`/certificates/${certificate.certificatePolicyNumber}?masterPolicyNumber=${policyNumber}`}
                                        name={
                                          certificate.certificatePolicyNumber
                                        }
                                      />,
                                      certificate.customerNumber,
                                      certificate.primaryParticipantName || '-',
                                      certificate.policyStatusCd || '-',
                                      certificate.effectiveDate
                                        ? moment(
                                            certificate.effectiveDate,
                                            'YYYY-MM-DDTHH:mm:ss'
                                          ).format('MM/DD/YYYY')
                                        : '-',
                                    ];
                                  }
                                )}
                              />
                            </TableWrapper>
                            <Paginator
                              leftButtonContent={
                                <Icon
                                  height="0.75rem"
                                  svg="left-chevron"
                                  width="0.5rem"
                                />
                              }
                              offset={values.offset}
                              onNextClick={() => {
                                form.change(
                                  'offset',
                                  values.offset + values.pageSize
                                );
                              }}
                              onPreviousClick={() => {
                                let newOffset = values.offset - values.pageSize;
                                if (newOffset < 0) {
                                  newOffset = 0;
                                }
                                form.change('offset', newOffset);
                              }}
                              pageTotal={
                                allPolicyCertificate.certificates.length
                              }
                              rightButtonContent={
                                <Icon
                                  height="0.75rem"
                                  svg="right-chevron"
                                  width="0.5rem"
                                />
                              }
                              total={allPolicyCertificate.certificates.length}
                            />
                          </>
                        );
                      }}
                    </Query>
                  </Card>
                </>
              );
            }}
          />
        );
      }}
    </ParentSize>
  );
};

PolicyCertificateTab.propTypes = {
  policyNumber: PropTypes.string.isRequired,
};

export default PolicyCertificateTab;
