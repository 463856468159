import React from 'react';
import Icon from '../Icon';

const DotsIcon = () => (
  <Icon
    p={2}
    svg="dots"
    sx={{
      '&:hover': { color: 'accent' },
      color: 'text.primary',
      transition: 'color 0.2s',
    }}
    width="1.75rem"
  />
);

export default DotsIcon;
