import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';
import Spinner from '@shoreag/base/Spinner';
import Tabs from '@shoreag/base/Tabs';
import { TooltipBox } from '@shoreag/base/Tooltip';
import Route from '../Route';
import allPolicy from '../../graphql/queries/all-policy.gql';
import dashboardConstants from '../../utilities/dashboard-constants';
import PolicyOverviewTab from '../PolicyOverivewTab';
import PolicyCertificateTab from '../PolicyCertificateTab';

const PolicyCertificatesDashboardPage = ({ policyNumber }) => {
  const { pageCriteria } = dashboardConstants.getCriterias({
    type: 'master_policy',
  });

  const { data, loading } = useQuery(allPolicy, {
    variables: {
      searchBody: JSON.stringify({
        ...{
          policyNumber: {
            type: 'Input',
            valueType: 'S',
          },
          values: { policyNumber },
        },
        ...pageCriteria,
      }),
    },
  });

  const policy = get(data, 'allPolicy', []);

  let tabs = [];
  if (data) {
    tabs = [
      {
        label: 'Overview',
        render: () => <PolicyOverviewTab policies={policy.policies} />,
        value: 'overview',
      },
      {
        label: 'Policy Certificates',
        render: () => <PolicyCertificateTab policyNumber={policyNumber} />,
        value: 'policy-certificates',
      },
    ];
  }

  return (
    <Route
      header={{
        icon: 'master-policy',
        title: policyNumber,
        type: 'master policy',
      }}
      isPrivate
    >
      {loading && <Spinner />}
      {!!data && (
        <>
          <Tabs
            defaultTab="overview"
            label="Section"
            name="section"
            tabs={tabs}
            type="tabs"
          />
          <TooltipBox id="tooltip" />
        </>
      )}
    </Route>
  );
};

PolicyCertificatesDashboardPage.propTypes = {
  policyNumber: PropTypes.string,
};

PolicyCertificatesDashboardPage.defaultProps = {
  policyNumber: null,
};

export default PolicyCertificatesDashboardPage;
